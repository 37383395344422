import { useState } from "react";
import { useForm } from "react-hook-form";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { Loader } from "../../component/Loader/Loader.component";
import { patchData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { toast } from "react-toastify";
import {
  getFromLocalStorage,
  postToLocalStorage,
} from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";

const schema = yup.object().shape({
  phoneNumber: yupValidators.phoneNumber,
});

interface ISignUpStepTwoVerification {
  setEdit: (newState: boolean) => void;
  setStep: (newState: number) => void;
}

const SignUpStepTwoVerification: React.FC<ISignUpStepTwoVerification> = ({
  setEdit,
  setStep,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ phoneNumber }: { phoneNumber: number }) => {
    setLoading(true);

    const reqBody = {
      phone_number: phoneNumber,
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPDATE_USER_PHONE_NUMBER}`,
        reqBody
      );

      setStep(2);
      postToLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER, phoneNumber);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setEdit(false);
    setLoading(false);
  };

  return (
    <>
      <div className="md:pt-10 lg:pt-[96px] md:pr-10 lg:pr-[120px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7">
            <h3 className="text-[26px] md:text-[32px] lg:leading-[38px] font-[300] pb-2">
              Enter Phone number
            </h3>
            <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
              Please provide your correct phone number
            </p>
          </div>
          <div className="w-full lg:w-3/5">
            <CustomInputField
              type="number"
              maxLength={11}
              placeholder="08100000000"
              label="Phone Number"
              hasIcon="+234"
              errors={errors.phoneNumber}
              showRequiredIcon
              {...register("phoneNumber")}
            />
          </div>
          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-3/5 flex justify-center mt-[30px] cursor-pointer"
            disabled={loading}
          >
            Continue
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {loading && <Loader />}
          </button>
        </form>
      </div>
    </>
  );
};

export default SignUpStepTwoVerification;
