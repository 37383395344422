import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import LandingLayout from "../../component/Layouts/LandingLayout.component";
import { ROUTES } from "../../helpers/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { ReactComponent as ShowPasswordIcon } from "../../assets/images/svg/eye-open-icon.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/images/svg/eye-close-icon.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { axiosInstance, getData, postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { toast } from "react-toastify";
import { Loader } from "../../component/Loader/Loader.component";
import { postToLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { AuthContext } from "../../context/auth";

const schema = yup.object().shape({
  email: yupValidators.email,
  password: yup.string().required("Please enter your password"),
});

const Login = () => {
  const { setIsAuthed, setMerchantCntxtStatus, setMerchantCntxtTerms } =
    useContext(AuthContext);
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { reRoute } = Object(state) as {
    reRoute?: boolean | null;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);

    const reqBody = {
      email,
      password,
    };

    try {
      const res: any = await postData(
        `${CONFIG.BASE_URL1}${apiEndpoints.LOGIN}`,
        reqBody
      );
      postToLocalStorage(LOCAL_STORAGE_KEYS.FIRSTNAME, res.data.first_name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.LASTNAME, res.data.last_name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER, res.data.phone_number);
      postToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, res.data.email);
      postToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, res.data.token);
      setIsAuthed(true);
      postToLocalStorage(LOCAL_STORAGE_KEYS.USERID, res.data.id);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
      const { data } = await getData(
        `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`
      );

      if (data) {
        postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data.merchant_id);
        postToLocalStorage(
          LOCAL_STORAGE_KEYS.BUSINESSNAME,
          data?.merchant?.name
        );
        console.log("data?.pin", data?.pin);
        postToLocalStorage(
          LOCAL_STORAGE_KEYS.MERCHANTTERMS,
          data?.attributes?.Profile?.terms_and_conditions === "accepted"
            ? true
            : false
        );
        setMerchantCntxtTerms(
          data.data?.attributes?.Profile?.terms_and_conditions === "accepted"
            ? true
            : false
        );
        postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTCREATED, data?.pin);
        // setMerchantCntxtProfile(data.profile_created);
        // Todo
        postToLocalStorage(
          LOCAL_STORAGE_KEYS.MERCHANTSTATUS,
          data?.pin ? "1" : "2"
        );
        setMerchantCntxtStatus(data.status);
        switch (true) {
          case data?.stage?.next === "INITIATE":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
          case data?.stage?.next === "VERIFY":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 2 } });

          case data?.stage?.next === "ADD_SERVICE":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 3 } });

          case data?.attributes?.Profile?.terms_and_conditions === undefined:
            return navigate(ROUTES.TERMS);

          case data?.pin !== null:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, false);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });

          default:
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
        }
      } else if (data === null) {
        return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
      }
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  const togglePasswordField = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <LandingLayout firstText="Speedy Business" secondText="Solutions ✨">
      <div className="md:pt-[50px] lg:pt-[110px] pl-[25px] pr-[25px] md:pr-[64px] lg:pr-[225px] overflow-hidden">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7">
            {reRoute ? (
              <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
                Login to your Aella Account
              </h3>
            ) : (
              <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
                Sign In
              </h3>
            )}
            {reRoute ? (
              <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
                We noticed you have an Aella account. Kindly login with your
                credentials to proceed.
              </p>
            ) : (
              <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
                Enter your work email and password to log in to the Aella
                Merchant Dashboard
              </p>
            )}
          </div>

          <CustomInputField
            type="email"
            maxLength={128}
            placeholder="user@example.com"
            label="Work Email"
            errors={errors.email}
            showRequiredIcon
            {...register("email")}
          />
          <CustomInputField
            type={passwordType}
            maxLength={128}
            placeholder="********"
            label="Password"
            showRequiredIcon
            errors={errors.password}
            hasActionButton={true}
            actionButtonText={
              passwordType === "password" ? (
                <ShowPasswordIcon />
              ) : (
                <HidePasswordIcon />
              )
            }
            onClickActionButton={togglePasswordField}
            {...register("password")}
          />

          <div className="flex  justify-end lg:leading-[17px] font-[300] mb-[29px]">
            <h1 className="text-aellaGray">Forgot Password? </h1>
            <Link
              className="text-aellaBlue pl-1 cursor-pointer"
              to={ROUTES.RESET_PASSWORD}
            >
              Click Here
            </Link>
          </div>
          <button
            type="submit"
            className={` bg-aellaBlue rounded text-white py-4 px-6 items-center w-full flex justify-center ${
              loading && "disabled:opacity-75"
            }`}
            disabled={loading}
          >
            Go To Dashboard
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {loading && (
              <div className="">
                <Loader />
              </div>
            )}
          </button>

          <p className="flex items-center justify-center mt-8 text-[16px] lg:leading-[20px] font-[300]">
            <span className="text-aellaGray pr-2">Don't have an account? </span>
            <Link className="text-aellaBlue cursor-pointer" to={ROUTES.SIGNUP}>
              Sign Up
            </Link>
          </p>
        </form>
      </div>
    </LandingLayout>
  );
};

export default Login;
