import { useState } from "react";
import { ReactComponent as MarkIcon } from "../../assets/images/svg/white-check-icon.svg";
import { ReactComponent as GrayMarkIcon } from "../../assets/images/svg/gray-check-icon.svg";
export type ServicesCardsType = {
  id: string;
  name: string;
  benefits: [];
  logo_url_white: string;
  logo_url_blue: string;
  description: string;
};
const ServicesCards = ({
  data,
  onSelect,
}: {
  data: ServicesCardsType;
  onSelect: any;
}) => {
  const [selected, setSelected] = useState(false);

  return (
    <div
      className={`${
        selected ? "bg-[#2054D2] text-[#F7F8F8]" : "bg-[#F7F8F8] text-aellaGray"
      } rounded-[5.9px] border-[0.7px] border-[#EDEFF5] flex-col justify-between w-full lg:w-[250px] p-[17px] cursor-pointer`}
      onClick={(e) => {
        setSelected(!selected);
        onSelect(e, data);
      }}
    >
      <div className="flex flex-row items-center pb-[6px]">
        <div className="w-[32px] h-[32px] pr-[6px]">
          {selected ? (
            <img src={data.logo_url_blue} alt="" />
          ) : (
            <img src={data.logo_url_white} alt="" />
          )}
        </div>
        <h1 className="text-[17px] lg:leading-[21px] font-[400]">
          {data.name}
        </h1>
      </div>

      <p className="text-[14px] lg:leading-[21px] font-[300] pb-[18px]">
        {data.description}
      </p>
      <div className="font-[400] text-[16px] lg:leading-[19px]"></div>
      {data?.benefits?.map((data: any, _id) => {
        const { benefit } = data;
        return <Benefits benefit={benefit} selected={selected} />;
      })}
    </div>
  );
};
export default ServicesCards;

const Benefits = ({
  benefit,
  selected,
}: {
  benefit: string;
  selected: boolean;
}) => {
  return (
    <div
      className={`${
        selected ? "text-[#F7F8F8]" : "text-aellaGray"
      } flex items-center w-full`}
    >
      {selected ? (
        <div className="w-[11.83px] h-[11.83px] pr-3">
          <MarkIcon />
        </div>
      ) : (
        <div className="w-[11.83px] h-[11.83px] pr-3">
          <GrayMarkIcon />
        </div>
      )}
      <p className="pl-1 font-[300] text-[14px] lg:leading-[21px]">{benefit}</p>
    </div>
  );
};
