import React from "react";
import { ChangeHandler } from "react-hook-form";

interface ICustomSelectDropdownProps {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: undefined | number;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean;
  onChange?: ChangeHandler;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  extraLabel?: string;
  bottomLabel?: string;
  rows?: number;
  children?: React.ReactNode | undefined;
  addPadding?: boolean;
  selectClassName?: string;
  errorMessage?: string;
}

const CustomSelectDropdown: React.FC<ICustomSelectDropdownProps> =
  React.forwardRef(
    (
      {
        name,
        children,
        label,
        errors,
        value,
        onChange,
        showRequiredIcon,
        extraLabel,
        readOnly,
        defaultValue,
        addPadding,
        errorMessage,
        selectClassName,
        ...otherProps
      },
      ref: any
    ) => {
      return (
        <>
          <div className="mb-[18px] lg:mb-[25px]">
            <div className="flex items-center">
              <label className="block text-aellaBlack text-[16px] font-[300] mb-[6px]">
                {label}
              </label>
              {showRequiredIcon && <sup className="ml-1 text-[#EB5757]">*</sup>}
            </div>
            {extraLabel?.length ? (
              <h1 className="text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
                {extraLabel}
              </h1>
            ) : null}
            <select
              className={`bg-white appearance-none border px-4 placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] ${
                addPadding ? "pl-10" : ""
              }  ${readOnly ? "text-aellaGray" : "text-aellaBlack"} ${
                errors ? "border-[#EB5757]" : "border-aellaLightGray"
              }   rounded w-full h-[48px] py-2 leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white  ${
                errors ? "border-[#EB5757]" : "focus:border-aellaBlue"
              }  ${selectClassName}`}
              name={name}
              ref={ref}
              value={value}
              onChange={onChange}
              disabled={readOnly}
              defaultValue={defaultValue}
              {...otherProps}
            >
              {children}
            </select>
            {/* <ErrorHandler errors={errors} /> */}

            {errors && (
              <p className="text-[#DD4F05] font-light text-[0.8rem]">
                {errorMessage}
              </p>
            )}
          </div>
        </>
      );
    }
  );

export default CustomSelectDropdown;
