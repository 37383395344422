export const identificationTypes = [
  { name: "International Passport", value: "International Passport" },
  { name: "Driver Licence", value: "Driver Licence" },
  { name: "National Identity Card", value: "National Identity Card" },
  { name: "Permanent Voter’s Card", value: "Permanent Voter’s Card" },
];

export const businesTypes = [
  {
    id: "Unregistered",
    name: "Unregistered Business",
    description:
      "An unregistered business is one that has not completed the legal procedures required to operate legally including CAC registration but is testing their ideas with real customers",
  },
  {
    id: "Registered",
    name: "Registered Business",
    description:
      "A registered business is a legally recognised business that has fulfilled all the necessary requirements and has obtained documents such as CAC registration to operate its business.",
  },
];
