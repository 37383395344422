import { useEffect, useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/svg/edit-icon.svg";
import PinInput from "react-pin-input";
import Countdown, { zeroPad } from "react-countdown";
import { hideWord } from "../../helpers/hideWord";
import { Loader } from "../../component/Loader/Loader.component";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { postData } from "../../apis/apiMethods";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useLocation } from "react-router-dom";

interface ISignUpStepTwo {
  setEdit: (newState: boolean) => void;
  setStep: (newState: number) => void;
}

const SignUpStepTwo: React.FC<ISignUpStepTwo> = ({ setEdit, setStep }) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [counter, setCounter] = useState(Date.now() + 60000);

  let phoneNumber = getFromLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER);

  const { state } = useLocation();
  const { signUpStep } = Object(state) as {
    signUpStep?: number | null;
  };

  useEffect(() => {
    if (signUpStep && signUpStep === 2) {
      resendOTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpStep]);

  const onSubmit = async () => {
    setLoading(true);
    const reqBody = {
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
      otp: pin,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.VALIDATE_OTP}`,
        reqBody
      );
      setStep(3);
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };

  const resendOTP = async () => {
    setLoading(true);
    const reqBody = {
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
      email_or_phone_number: phoneNumber,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.RESEND_OTP}`,
        reqBody
      );
      setCounter(Date.now() + 60000);
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mb-7 pt-6 lg:pt-[96px]">
        <h3 className="text-[26px] md:text-[32px] lg:leading-[38px] font-[300] pb-2">
          Phone number verification
        </h3>
        <p className="text-aellaGray text-[14px] md:text-[16px] font-[200] lg:leading-[20px]">
          Your verfication code was sent to
          <span className="text-aellaBlue pl-1">
            {phoneNumber
              ? phoneNumber.includes("234")
                ? hideWord(`+${phoneNumber}`)
                : hideWord(`+234${phoneNumber}`)
              : ""}
          </span>
        </p>
      </div>
      <div className="border border-[#C5D4F6] w-[286px] rounded-[30px] bg-[#F3F5FC] py-2 px-[15px] text-[14px] lg:leading-[14px] font-[250] flex items-center mb-12">
        Entered a wrong phone number?
        <span
          className="flex items-center text-aellaBlue pl-2 cursor-pointer"
          onClick={() => {
            setEdit(true);
          }}
        >
          Edit.
          <span className="pl-2">
            <EditIcon />
          </span>
        </span>
      </div>

      <PinInput
        length={6}
        placeholder="0"
        initialValue=""
        onChange={(value, index) => setPin(value)}
        type="numeric"
        inputMode="number"
        inputStyle={{
          border: "none",
          borderBottom: "1px solid #D8D8D8",
          marginRight: "5px",
          paddingRight: "0px",
          paddingLeft: "0px",
        }}
        inputFocusStyle={{ borderColor: "#2054D2" }}
        onComplete={(value, index) => {}}
        autoSelect={true}
      />

      <div className="py-[39px]">
        <Countdown
          date={counter}
          key={counter}
          renderer={({ minutes, seconds, completed }) => {
            if (completed) {
              return (
                <button
                  className="text-aellaBlue disabled:opacity-75 text-[16px] lg:leading-[19px] font-[300] cursor-pointer"
                  onClick={resendOTP}
                  disabled={loading}
                >
                  Resend Code
                </button>
              );
            } else {
              return (
                <span className="text-[16px] lg:leading-[19px] font-[300] text-aellaGray ">
                  Resend code in
                  <span className="text-aellaBlue ">
                    {" "}
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                  </span>
                </span>
              );
            }
          }}
        />
      </div>

      <button
        type="submit"
        className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full md:w-1/2 flex justify-center  cursor-pointer"
        disabled={loading || !pin}
        onClick={onSubmit}
      >
        Continue
        <span className="pl-2">
          <NextIcon />{" "}
        </span>
        {loading && <Loader />}
      </button>
    </>
  );
};

export default SignUpStepTwo;
